@font-face {
  font-family: YekanBakh;
  src: url(../src/assets/fonts/YekanBakhFaNum-Regular.woff) format("woff2"),
    url(../src/assets/fonts/YekanBakhFaNum-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: YekanBakh;
  src: url(../src/assets/fonts/YekanBakhFaNum-Bold.woff) format("woff2"),
    url(../src/assets/fonts/YekanBakhFaNum-Bold.woff) format("woff");
  font-weight: 700;
}

@font-face {
  font-family: YekanBakh;
  src: url(../src/assets/fonts/YekanBakhFaNum-Medium.woff) format("woff2"),
    url(../src/assets/fonts/YekanBakhFaNum-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}

body {
  direction: rtl;
  font-family: "YekanBakh", sans-serif !important;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  padding: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}