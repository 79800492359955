* {
    font-size: 62, 5%;
    box-sizing: border-box;
    margin: 0;
  }
  
  .body-ranking {
    height: 100%;
    width: 100%;
    min-height: 100vh;
    background-color: #fbfaff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: YekanBakh
  }

  .bg-dark {
    background-color: #141a39 !important;
  }
  
  button {
    font-family: YekanBakh
  }

  main {
    width: 40rem;
    /* height: 43rem; */
    background-color: #ffffff;
    -webkit-box-shadow: 0px 5px 15px 8px #e4e7fb;
    box-shadow: 0px 5px 15px 8px #e4e7fb;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.5rem;
  }
  
  #header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.5rem 2rem;
  }
  
  .share {
    background-color: #090258;
    border: 0;
    padding: 10px 20px;
    border-radius: 10px;
  }
  
  .share:active {
    border-bottom: 0;
  }
  
  .share i {
    color: #fff;
    font-size: 2rem;
    font-style: normal;
  }
  
  h1 {
    font-size: 1.7rem;
    font-weight: 700;
    color: #141a39;
    cursor: default;
  }
  
  #leaderboard {
    width: 100%;
    position: relative;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    color: #141a39;
    cursor: default;
  }
  
  tr {
    transition: all 0.2s ease-in-out;
    border-radius: 0.2rem;
  }
  
  tr:not(:first-child):hover {
    background-color: #fff;
    transform: scale(1.1);
    -webkit-box-shadow: 0px 5px 15px 8px #e4e7fb;
    box-shadow: 0px 5px 15px 8px #e4e7fb;
  }
  
  tr:nth-child(odd) {
    background-color: #f9f9f9;
  }
  
  tr:nth-child(1) {
    color: #fff;
  }
  
  td {
    height: 5rem;
    font-size: 1.4rem;
    padding: 1rem 2rem;
    position: relative;
  }
  
  .number {
    width: 1rem;
    font-size: 2.2rem;
    font-weight: bold;
    text-align: left;
  }
  
  .name {
    direction:ltr;
    text-align: left;
    font-size: 1.2rem;
    font-weight: 700;
  }
  
  .points {
    font-weight: bold;
    font-size: 1.3rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .points:first-child {
    width: 10rem;
  }
  
  .gold-medal {
    height: 3rem;
    margin-left: 1.5rem;
  }
  
  .ribbon {
    width: 42rem;
    height: 5.5rem;
    top: -0.5rem;
    background-color: #5c5be5;
    position: absolute;
    left: -1rem;
    -webkit-box-shadow: 0px 15px 11px -6px #7a7a7d;
    box-shadow: 0px 15px 11px -6px #7a7a7d;
  }
  
  .ribbon::before {
    content: "";
    height: 1.5rem;
    width: 1.5rem;
    bottom: -0.8rem;
    left: 0.35rem;
    transform: rotate(45deg);
    background-color: #5c5be5;
    position: absolute;
    z-index: -1;
  }
  
  .ribbon::after {
    content: "";
    height: 1.5rem;
    width: 1.5rem;
    bottom: -0.8rem;
    right: 0.35rem;
    transform: rotate(45deg);
    background-color: #5c5be5;
    position: absolute;
    z-index: -1;
  }
  
  #buttons {
    width: 100%;
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    gap: 2rem;
  }
  
  .exit {
    width: 11rem;
    height: 3rem;
    font-size: 1.3rem;
    text-transform: uppercase;
    color: #7e7f86;
    border: 0;
    background-color: #fff;
    border-radius: 2rem;
    cursor: pointer;
  }
  
  .exit:hover {
    border: 0.1rem solid #5c5be5;
  }
  
  .continue {
    width: 11rem;
    height: 3rem;
    font-size: 1.3rem;
    color: #fff;
    text-transform: uppercase;
    background-color: #5c5be5;
    border: 0;
    border-bottom: 0.2rem solid #3838b8;
    border-radius: 2rem;
    cursor: pointer;
  }
  
  .continue:active {
    border-bottom: 0;
  }
  
  @media (max-width: 740px) {
      * {
        font-size: 70%;
      }
  }
  
  @media (max-width: 500px) {
      * {
        font-size: 55%;
      }
  }
  
  @media (max-width: 390px) {
      * {
        font-size: 45%;
      }
  }



  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: white;
    padding: 8px;
    border-radius: 10px;
    text-align: center;
    width: 630px;
}

.input-rank {
    padding: 5px 20px;
    border: 1px solid #9e9e9e;
    border-radius: 16px;
}

.btn-send {
    background-color: #8a1c1c;
    border: 0;
    padding: 5px 20px;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
}

.btn-send:disabled {
    background-color: #b7b7b7;
    cursor: not-allowed;
}

.res-msg {
    background-color: #1b1b1b;
    color: #fff;
    padding: 5px;
    border-radius: 10px;
}

.title-rank {
    background-color: #00575f;
    padding: 13px 16px;
    border-radius: 16px;
    color: #fff;
}