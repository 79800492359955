* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.color-white {
    color: #fff;
}

.fs-130{
    font-size: 130px;
}

.fs-90 {
    font-size: 90px;
}

.fs-35 {
    font-size: 35px;
 }
 .fs-60 {
    font-size: 60px;
 }

 .fs-40 {
    font-size: 40px;
 }

.fw-700 {
    font-weight: 700;
}

.img-intro-logo {
    width: 110px;
    height: auto;
}

.margin-auto {
    margin: auto !important;
}

.ml-30 {
    margin-left:30px !important
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-80 {
    margin-top: 80px !important;
}

.mt-130 {
    margin-top: 130px !important;
}

.body-intro {
    background: #19A814;
    height: 100vh;
    display: flex;
    justify-content: space-around;
    direction: rtl;
}

.body-intro-container {
    padding: 2rem;
}

.img-intro {
    height: 300px;
    width: 300px;
}

.img-intro-insta {
    height: 100px;
    width: 100px;
}

.d-flex {
    display: flex;
}

.button-white {
    border-radius: 100px;
    background: #FFF;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.25);
    color: #19A814;
    padding: 16px 20px;
    display: flex;
    justify-content: center;
}

.justify-content-center {
    display: flex;
    justify-content: center;
}

.flex-direction-column {
    flex-direction: column;
}
.align-items-center {
    align-items:center
}