* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.h-0 {
    height: auto !important;
}

.w-100 {
    width: 100%;
}

.w-900 {
    width: 900px !important;
}

.w-50 {
    width: 50%;
}

.color-white {
    color: #fff;
}

.color-green {
    color: #19A814 !important
}

.p-50 {
    padding: 50px;
}

.p-100 {
    padding: 100px;
}

.pt-100 {
    padding-top: 100px;
}

.fs-32 {
    font-size: 32px;
}

.fs-35 {
    font-size: 35px;
}

.fs-60 {
    font-size: 60px;
}

.fs-130 {
    font-size: 130px;
}

.radio-btn {
    width: 70px;
    height: 70px;
    margin-left: 30px;
}

.fs-90 {
    font-size: 90px;
}

.text-center {
    text-align: center;
}

.input-mob {
    width: 390px;
    height: 90px;
    border-radius: 40px;
    border: 1px solid #999;
    padding: 20px;
    font-size: 45px;
}


.fw-700 {
    font-weight: 700;
}

.ml-30 {
    margin-left: 30px !important
}

.ml-150 {
    margin-left: 150px !important;
}

.mb-16 {
    margin-bottom: 16px !important;
}

.mt-16 {
    margin-top: 16px !important;
}

.mt-25 {
    margin-top: 25px !important;
}

.mt-35 {
    margin-top: 35px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-80 {
    margin-top: 80px !important;
}

.justify-content-center {
    display: flex;
    justify-content: center;
}

.justify-content-space-between {
    justify-content: space-between;
}

.flex-direction-column {
    flex-direction: column;
}

.align-items-center {
    align-items: center
}

.align-items-end {
    align-items: flex-end;
}

.pointer {
    cursor: pointer;
}


.body-questions {
    background: #fff;
    height: 100vh;
    display: flex;
    justify-content: space-around;
    direction: rtl;
}

.body-question-container {
    width: 70%;
    justify-content: center;
}

.img-intro {
    height: 130px;
    width: 130px;
}

.img-question {
    width: 500px;
    height: auto;
}

.img-answers {
    width: 150px;
    height: auto;
}

.img-result {
    width: 380px;
    height: 380px;
    ;
}

.img-insta {
    width: 200px;
    height: 200px;
    ;
}

.download {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.download-text {
    background-color: #000;
    color: #fff;
    border-radius: 40px;
}

.d-flex {
    display: flex;
}

.button-white {
    border-radius: 100px;
    background: #FFF;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.25);
    color: #19A814;
    padding: 16px 20px;
    display: flex;
    justify-content: center;
}

.button-red {
    border-radius: 100px;
    background: #3d1313;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.25);
    color: #ffffff;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
}

.button-green {
    border-radius: 100px;
    background: #19A814;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    padding: 16px 20px;
    display: flex;
    justify-content: center;
}

.button-gray {
    border-radius: 100px;
    background: rgba(18, 18, 18, 0.05);
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.25);
    color: #121212;
    padding: 16px 20px;
    display: flex;
    justify-content: center;
}

.answers-body {
    border-radius: 12px;
    background: #FAFAFA;
}

.answers-container {
    padding: 40px;
}

.loader {
    border: 16px solid #f3f3f3;
    /* Light grey */
    border-top: 16px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.bd-loader {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-mobile {
    display: flex;
    width: 460px;
    height: 75px;
    padding: 15px;
    align-items: center;
    border-radius: 100px;
    border: 1px solid var(--action-state-onlight-active, #131313);
}

::placeholder {
    font-family: YekanBakh;
    font-size: 20px;
}

.p-relative {
    position: relative !important;
}

.p-absolute {
    position: absolute !important;
}

.p-input {
    left: 15px;
    top: 15px;
    width: 45px;
    height: 45px;
}

input[type="text"]
{
    font-family: YekanBakh;
    font-size:24px;
    margin-right: 20px;
    font-weight: 700;
    color: #5d5d5e;
}